'use client';

import React, { useEffect } from 'react';
import AOS from 'aos';

const AOSInitializer = ({ children }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default AOSInitializer;
