/* eslint-disable react/no-unescaped-entities */
'use client';

import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useParams } from 'next/navigation';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../../../i18n-config';
import Image from 'next/image';

const LKSectionSeven = () => {
  const params = useParams();
  const { t } = useTranslation(params?.lang as Locale);

  const [my_swiper, set_my_swiper] = useState<any>({});
  const [position, setPosition] = useState({
    isEnd: false,
    isBegining: true,
  });

  return (
    <section className="section-7 d-flex py-5" id="aboutUs">
      <Container fluid={'lg'} className="my-auto">
        <div className="text-left mb-5">
          <h2 className="fs-1 lk-text-red mb-4">{t('LKSectionSeven/key10')}</h2>
          <p style={{ fontSize: 16, color: '#54575E' }} className="w-50 fw-light">
            {t('LKSectionSeven/key3')}
          </p>
        </div>

        <Row className="justify-content-center mb-5">
          <Col lg={10}>
            <div className="lk-customers-review position-relative">
              <Image
                className="d-lg-block d-none"
                src="https://cdn.1cdn.app/application/LUMBERJACK/2208240642_Circularsaw_1.svg"
                alt="L-K.io"
                width={100}
                height={100}
                title="Circularsaw"
                // data-aos="fade-right"
              />
              <div className="lk-review-item">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1.2}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                  }}
                  navigation={false}
                  modules={[Navigation]}
                  onSlideChange={() => {
                    if (my_swiper.isEnd) {
                      setPosition({
                        isEnd: true,
                        isBegining: false,
                      });
                    } else if (my_swiper.isBeginning) {
                      setPosition({
                        isEnd: false,
                        isBegining: true,
                      });
                    } else {
                      setPosition({
                        isEnd: false,
                        isBegining: false,
                      });
                    }
                  }}
                  onInit={(ev) => {
                    set_my_swiper(ev);
                  }}
                >
                  <SwiperSlide>
                    <blockquote>
                      <em>"{t('LKSectionSeven/key2')}"</em>
                    </blockquote>
                    <h3>Sara Grint</h3>
                    <small>{t('LKSectionSeven/key9')}</small>
                  </SwiperSlide>
                  <SwiperSlide>
                    <blockquote>
                      <em>"{t('LKSectionSeven/key7')}"</em>
                    </blockquote>
                    <h3>Alan Davies</h3>
                    <small>{t('LKSectionSeven/key4')}</small>
                  </SwiperSlide>
                  <SwiperSlide>
                    <blockquote>
                      <em>"{t('LKSectionSeven/key6')}"</em>
                    </blockquote>
                    <h3>James Maynard</h3>
                    <small>{t('LKSectionSeven/key9')}</small>
                  </SwiperSlide>
                  <SwiperSlide>
                    <blockquote>
                      <em>"{t('LKSectionSeven/key1')}"</em>
                    </blockquote>
                    <h3>Mary Stone</h3>
                    <small>Instagram {t('LKSectionSeven/key12')}</small>
                  </SwiperSlide>
                </Swiper>
                <div className="lk-offers-nav d-flex align-items-center justify-content-start my-4">
                  <button
                    className="btn btn-sm btn-link fs-5"
                    disabled={position.isBegining}
                    onClick={() => my_swiper.slidePrev()}
                    role="button"
                    aria-label="previous slide"
                  >
                    <BsChevronLeft />
                  </button>
                  <button
                    className="btn btn-sm btn-link fs-5"
                    disabled={position.isEnd}
                    onClick={() => my_swiper.slideNext()}
                    role="button"
                    aria-label="next slide"
                  >
                    <BsChevronRight />
                  </button>
                </div>
              </div>
              <Image
                className="d-lg-block d-none"
                src="https://cdn.1cdn.app/application/LUMBERJACK/2208240642_Handsaw.svg"
                alt="L-K.io"
                width={130}
                height={130}
                title="Handsaw"
              />
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center mt-5">
          <Col lg={10}>
            <div className="lk-site-note mt-5">
              <Row>
                <Col
                  xl={{ order: 1, span: 10 }}
                  lg={{ order: 1, span: 9 }}
                  md={{ order: 1, span: 7 }}
                  xs={{ order: 2, span: 12 }}
                  className="mt-lg-5"
                >
                  <div className="lk-site-note-box">
                    <blockquote>
                      <em>
                        “{t('LKSectionSeven/key5')}” <br />
                        <b>{t('LKSectionSeven/key11')}</b>
                      </em>
                    </blockquote>
                    <p>{t('LKSectionSeven/key8')}</p>
                  </div>
                </Col>
                <Col
                  xl={{ order: 1, span: 2 }}
                  lg={{ order: 1, span: 3 }}
                  md={{ order: 1, span: 5 }}
                  xs={{ order: 1, span: 12 }}
                  className="text-center mb-lg-0 mb-3"
                >
                  <Image
                    src="https://cdn.1cdn.app/application/LUMBERJACK/2208240641_Guarantee.svg"
                    alt="L-K.io"
                    width={300}
                    height={300}
                    title="Guarantee"
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LKSectionSeven;
