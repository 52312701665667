'use client';

import { useParams } from 'next/navigation';
import { Col, Container, Row } from 'react-bootstrap';
import { Locale } from '../../../../i18n-config';
import { useTranslation } from '@/i18n/client';
import Image from 'next/image';

const LKSectionThree = () => {
  const params = useParams();
  const { t } = useTranslation(params?.lang as Locale);

  // Don't remove this
  // [
  //   {
  //     file: '24021109371186_BigWebBg.png',
  //     cdnPath: 'https://cdn.1cdn.app/application/LUMBERJACK/24021109371186_BigWebBg.png',
  //   },
  //   {
  //     file: '24021109371197_MobileBg.png',
  //     cdnPath: 'https://cdn.1cdn.app/application/LUMBERJACK/24021109371197_MobileBg.png',
  //   },
  //   {
  //     file: '24021109371110_TabletBg.png',
  //     cdnPath: 'https://cdn.1cdn.app/application/LUMBERJACK/24021109371110_TabletBg.png',
  //   },
  //   {
  //     file: '24021109371131_TabletBg.png',
  //     cdnPath: 'https://cdn.1cdn.app/application/LUMBERJACK/24021109371131_TabletBg.png',
  //   },
  // ];

  return (
    <section className="d-flex section-3 py-5 position-relative" id="intro">
      <Image
        alt="Lumberjack section two background"
        src={'https://cdn.1cdn.app/application/LUMBERJACK/2208240702_section3WebBg.png'}
        quality={100}
        fill
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        style={{
          objectFit: 'cover',
          zIndex: 10,
        }}
        priority
        placeholder="empty"
      />
      <Container fluid={'lg'} className="my-auto" style={{ zIndex: 11 }}>
        <div className="text-center my-5">
          <h2 className="fs-1 lk-text-red mb-4">{t('LKSectionThree/key5')}</h2>
          <p style={{ fontSize: 14, color: '#54575E' }} className="w-50 m-auto">
            {t('LKSectionThree/key3')}
          </p>
        </div>
        <Row className="justify-content-center g-lg-5">
          <Col lg={4} md={4} xs={12}>
            <div className="lk-cs-card first">
              <Image
                className="img-fluid mb-3"
                src="https://cdn.1cdn.app/application/LUMBERJACK/24021111303366_2208240629_traking.png"
                alt="L-K.io"
                width={50}
                height={50}
                title="tracking"
              />
              <p>{t('LKSectionThree/key11')}</p>
              <p>{t('LKSectionThree/key9')}</p>
              <p className="mt-lg-4 mt-3 mb-0">{t('LKSectionThree/key10')}</p>
            </div>
          </Col>
          <Col lg={4} md={4} xs={12}>
            <div className="lk-cs-card second">
              <Image
                className="img-fluid mb-3"
                src="https://cdn.1cdn.app/application/LUMBERJACK/24021111303369_2208240629_supporting.png"
                alt="L-K.io"
                width={50}
                height={50}
                title="support"
              />
              <p>{t('LKSectionThree/key7')}</p>
              <p>{t('LKSectionThree/key4')}</p>
              <p className="mt-lg-4 mt-3 mb-0">{t('LKSectionThree/key2')}</p>
            </div>
          </Col>
          <Col lg={4} md={4} xs={12}>
            <div className="lk-cs-card third">
              <Image
                className="img-fluid mb-3"
                src="https://cdn.1cdn.app/application/LUMBERJACK/2402111130333_2208240629_balanceTargeting.png"
                alt="L-K.io"
                width={50}
                height={50}
                title="balance targeting"
              />
              <p>{t('LKSectionThree/key1')}</p>
              <p>{t('LKSectionThree/key6')}</p>
              <p className="mt-lg-4 mt-3 mb-0">{t('LKSectionThree/key8')}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LKSectionThree;
