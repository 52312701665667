'use client';

import { Col, Container, Row } from 'react-bootstrap';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useTranslation } from '@/i18n/client';
import { useParams } from 'next/navigation';
import { Locale } from '../../../../i18n-config';
import Link from 'next/link';
import Image from 'next/image';

const LKSectionSix = () => {
  const params = useParams();
  const { t } = useTranslation(params?.lang as Locale);
  const [size] = useWindowSize();

  return (
    <section className="section-6 py-5" id="plans">
      <Container fluid={'lg'} className="my-auto">
        <Row className="align-items-center gy-5">
          <Col lg={3} xs={12} className="text-center">
            {size > 820 ? (
              <Image
                src="/img/left-features.svg"
                alt="l-k.io"
                className="img-fluid w-100 h-100"
                // data-aos="fade-up-right"
                width={0}
                height={0}
              />
            ) : (
              <Image
                src="/img/top-features.svg"
                alt="l-k.io"
                className="img-fluid w-100 h-100"
                sizes="100vw"
                width={0}
                height={0}
              />
            )}
          </Col>
          <Col lg={6} xs={12}>
            <div className="d-flex flex-column align-items-center h-100 content">
              <h2>
                {t('LKSectionSix/key1')} <b>{t('LKSectionSix/key5')}</b>
              </h2>
              <p>{t('LKSectionSix/key4')}</p>
              <div className="actions mt-auto mx-auto">
                <Link href={`/home/${params?.lang}/plans`} className="btn btn-danger rounded-pill">
                  {t('LKSectionSix/key3')}
                </Link>
                <Link href={`/home/${params?.lang}/plans`} className="btn btn-dark rounded-pill">
                  {t('LKSectionSix/key2')}
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={3} xs={12} className="text-center">
            {size > 820 ? (
              <Image
                sizes="100vw"
                width={0}
                height={0}
                src="/img/right-features.svg"
                alt="l-k.io"
                className="img-fluid w-100 h-100"
                // data-aos="fade-up-left"
              />
            ) : (
              <Image
                sizes="100vw"
                width={0}
                height={0}
                src="/img/bottom-features.svg"
                alt="l-k.io"
                className="img-fluid w-100 h-100"
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LKSectionSix;
