'use client';

import { useParams } from 'next/navigation';
import { useContext } from 'react';
import { Container, Row, Col, Accordion, useAccordionButton, AccordionContext } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsDashCircle, BsPlusCircle } from 'react-icons/bs';
import { Locale } from '../../../../i18n-config';

export function ContextAwareToggle({ children, eventKey, callback }: { children: any; eventKey: any; callback: any }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <>
      {isCurrentEventKey ? (
        <div
          style={{ cursor: 'pointer' }}
          className="w-100 d-flex align-items-center justify-content-between"
          onClick={decoratedOnClick}
        >
          {children}
          <BsDashCircle />
        </div>
      ) : (
        <div
          style={{ cursor: 'pointer' }}
          className="w-100 d-flex align-items-center justify-content-between"
          onClick={decoratedOnClick}
        >
          {children}
          <BsPlusCircle />
        </div>
      )}
    </>
  );
}

const LKSectionEight = () => {
  const params = useParams();
  const { t } = useTranslation(params?.lang as Locale);

  const questions = [
    {
      question: t('LKSectionEight/key12'),
      answer: t('LKSectionEight/key7'),
    },
    {
      question: t('LKSectionEight/key11'),
      answer: t('LKSectionEight/key1'),
    },
    {
      question: t('LKSectionEight/key10'),
      answer: t('LKSectionEight/key8'),
    },
    {
      question: t('LKSectionEight/key5'),
      answer: t('LKSectionEight/key9'),
    },
    {
      question: t('LKSectionEight/key3'),
      answer: t('LKSectionEight/key6'),
    },
  ];

  return (
    <section className="section-8 d-flex py-5">
      <Container fluid={'lg'} className="my-auto">
        <div className="text-center mb-3">
          <h2 className="fs-1 lk-text-red mb-4">{t('LKSectionEight/key2')}</h2>
          <p style={{ fontSize: 16, color: '#54575E' }} className="w-50 m-auto fw-light">
            {t('LKSectionEight/key4')}
          </p>
        </div>

        <Row className="justify-content-center mt-5">
          <Col lg={10}>
            <Accordion>
              {questions.map((question: { question: string; answer: string }, index: any) => (
                <div className="lk-accordion-item" key={index}>
                  <div className="d-flex align-items-center justify-content-between">
                    <ContextAwareToggle eventKey={index} callback={undefined}>
                      <h3 className="fs-6">{question.question}</h3>
                    </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey={index}>
                    <div className="py-4">
                      <p className="mb-0">{question.answer}</p>
                    </div>
                  </Accordion.Collapse>
                </div>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LKSectionEight;
