'use client';

import { useEffect, useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import useScrollListener from '../../hooks/useScrollListener';

const ScrollTop = () => {
  const scroll = useScrollListener();
  const [setShowScrollTop, setSetShowScrollTop] = useState(false);

  useEffect(() => {
    if (scroll.y > 700) setSetShowScrollTop(true);
    else setSetShowScrollTop(false);
  }, [scroll.y]);

  const handleScrollTop = () => {
    if (window.location.hash) window.history.replaceState('', document.title, window.location.pathname);

    window.scrollTo(0, 0);
  };

  return (
    <div
      className={`scroll-top ${setShowScrollTop ? 'active' : ''}`}
      onClick={handleScrollTop}
      // data-aos="fade-left"
    >
      <BsChevronUp size={25} />
    </div>
  );
};

export default ScrollTop;
